import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { addHours, format } from "date-fns";
import { parseInt } from "lodash";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { ProductInstanceIdField } from "../../components/fields";
import {
  Allocation,
  Delivery,
  HospitalRoom,
  ProductInstance,
} from "../../types";
import { deliveryTypes } from "../deliveries/util";

const CreateDeliveryButton: React.FC<{ instanceId: number }> = ({
  instanceId,
}) => {
  const [disabled, setDisabled] = React.useState(false);
  const notify = useNotify();
  const [submit, { loading, error, data, loaded }] = useMutation({
    type: "create",
    resource: "deliveries",
    payload: {
      data: {
        note_to_driver: deliveryTypes.COLLECTION,
        // type: deliveryTypes.COLLECTION,
        product_instance_id: instanceId,
        target_location_id: process.env.REACT_APP_WAREHOUSE_LOCATION_ID, // @todo set this to warehouse location
        // assume that the target delivery time is 2 hours.
        // Please confirm with client, if 2 hours is a good number.
        target_delivery_time: format(
          addHours(new Date(), 2),
          "yyyy-MM-dd'T'HH:mm"
        ),
      },
    },
  });

  const handleClick = () => submit();

  React.useEffect(() => {
    if (loaded && (data as Delivery) && !error) {
      notify(`Delivery order has been created with ID: ${data.id}`, "success");
    }
  }, [loaded, data, error, notify]);

  React.useEffect(() => setDisabled(loading), [loading]);

  return (
    <>
      {loaded && !error ? (
        <div>Delivery Created</div>
      ) : (
        <Button
          variant="contained"
          startIcon={<CheckIcon />}
          size="small"
          color="primary"
          onClick={handleClick}
          disabled={disabled}
        >
          Create Delivery
        </Button>
      )}
    </>
  );
};

const ProductInstanceDataGrid: React.FC<{ record?: HospitalRoom }> = ({
  record,
}) => {
  if (!record) {
    return <div>Loading...</div>;
  }
  const {
    product_instances: instances,
    active_allocations: activeAllocations,
  } = record;
  if (!instances) {
    return <Box>No sets in this location.</Box>;
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Set ID",
      width: 500,
      renderCell: (params) => {
        // console.log("params :>> ", params);
        // @ts-ignore
        return <ProductInstanceIdField record={params.row} />;
      },
    },
    {
      field: "is_consigned",
      headerName: "Consignment",
      width: 200,
      renderCell: (params: GridCellParams) => {
        return params.value ? <CheckIcon /> : <>-</>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
    },
    {
      field: "can_return",
      headerName: "Can Return",
      width: 200,
      renderCell: (params: GridCellParams) => {
        return params.value ? (
          <CreateDeliveryButton instanceId={params.id as number} />
        ) : (
          <ClearIcon />
        );
      },
    },
  ];

  /**
   * A helper function to check if a product instance can be returned
   * to the warehouse.
   *
   * @param instance
   * @param activeAllocations
   * @returns
   */
  const canReturn = (
    instance: ProductInstance,
    activeAllocations: Allocation[]
  ) => {
    if (instance.is_consigned) {
      return false;
    }

    // if product is in activeAllocations, then return false
    const isActive = activeAllocations.find(
      (allocation) => allocation.product_instance_id === instance.id
    );
    if (isActive) {
      return false;
    }

    // if there's no active deliveries, this instance can be sent back to warehouse
    const { active_deliveries = [] } = instance;
    if (active_deliveries.length === 0) {
      return true;
    }

    // if the instance has active deliveries, and one or more of that deliveries
    // is to warehouse, then this instance cannot be sent back to warehouse.
    const warehouseLocationId = parseInt(
      // @ts-ignore
      process.env.REACT_APP_WAREHOUSE_LOCATION_ID
    );
    const toWarehouse = active_deliveries.filter(
      (delivery) => delivery.target_location_id === warehouseLocationId
    );
    if (toWarehouse.length !== 0) {
      return false;
    }

    return true;
  };

  // flatten the objects
  const rows = instances.map((instance) => ({
    ...instance,
    type: instance.product.type,
    can_return: canReturn(instance, activeAllocations ?? []),
  }));

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} pageSize={10} />
    </div>
  );
};

export default ProductInstanceDataGrid;
