import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { format } from "date-fns";
import { DropzoneArea } from "material-ui-dropzone";
import { useMutation, useNotify, useQueryWithStore } from "ra-core";
import React from "react";
import { Delivery, Location } from "../../../types";
import { convertIsoToLocale } from "../../../utils/date";
import { deliveryActions } from "../util";

interface Props {
  record: Delivery;
}
const EndDeliveryDialog: React.FC<Props> = (props) => {
  const { record: delivery } = props;
  console.log("delivery :>> ", delivery);
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const [dropOffTime, setDropOffTime] = React.useState<string>(
    format(new Date(), "yyyy-MM-dd'T'HH:mm")
  );
  const [dropOffLocation, setDropOffLocation] = React.useState<Location>();
  const [dropOffNote, setDropOffNote] = React.useState<string>("");
  const [delivered_images, setDeliveredImages] = React.useState<
    { url: string; type: string }[]
  >([]);

  // getting all locations
  const { data: locations } = useQueryWithStore({
    type: "getList",
    resource: "locations",
    payload: {
      sort: { field: "name", order: "asc" },
      filter: { status: "active", is_virtual: false },
      pagination: { page: 1, perPage: 1000 },
    },
  });

  React.useEffect(() => console.log(dropOffLocation), [dropOffLocation]);
  // initialise the dropoff location to follow the Allocation
  React.useEffect(() => {
    console.log("locations :>> ", locations);
    if (!locations) {
      return;
    }

    const selectedLocation: Location | undefined = locations.find(
      (location: Location) => location.id === delivery.target_location_id
    );
    console.log("selectedLocation :>> ", selectedLocation);
    if (!selectedLocation) {
      return;
    }
    setDropOffLocation(selectedLocation);
  }, [delivery.target_location_id, locations]);

  const [handleAccept] = useMutation(
    {
      type: "update",
      resource: "deliveries",
      payload: {
        id: delivery.id ?? "",
        data: {
          action: deliveryActions.FINISH,
          delivered_time: dropOffTime,
          delivered_location_id: dropOffLocation?.id,
          delivered_note: dropOffNote,
          delivered_images,
        },
      },
    },
    {
      onSuccess: () => {
        notify(`Successfully finish a Delivery Order.`, "info");
        setOpen(false);
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const convertFileToBase64 = (file: File) => {
    var reader = new FileReader();
    return new Promise((resolve) => {
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleSelectFile = async (files: File[]) => {
    // convert file to base64
    const convertAll = async (files: File[]) =>
      Promise.all(
        files.map(async (file: File) => {
          return await convertFileToBase64(file);
        })
      );

    convertAll(files)
      .then((base64s) =>
        base64s.map((base64) => ({
          url: base64,
          type: "image",
        }))
      )
      .then((delivered_images) =>
        setDeliveredImages(delivered_images as { url: string; type: string }[])
      );
  };

  return (
    <>
      <Button color="primary" onClick={handleClickOpen} size={"small"}>
        Finish Delivery
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">Finish Delivery</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm to finish Delivery.
            <br />
            <Paper variant="outlined">
              <Box p="10px" my="5px">
                Delivery ID: {delivery.id}
                <br />
                Product:{" "}
                {`${delivery.product_instance.product.name} (${delivery.product_instance.name})`}
                <br />
                Pickup Location: {delivery.product_instance.location.name}
                <br />
                Destination Location: {delivery.target_location.name}
                <br />
                Deliver Before:{" "}
                {convertIsoToLocale(delivery.target_delivery_time)}
              </Box>
            </Paper>
            <Box py="5px">
              <TextField
                label="Dropoff Time"
                fullWidth
                type="datetime-local"
                value={dropOffTime}
                onChange={(e) => setDropOffTime(e.target.value)}
              />
            </Box>
            <Box py="5px">
              <Autocomplete
                id="select-location"
                options={locations}
                getOptionLabel={(location) => location.name}
                renderInput={(params) => (
                  <TextField {...params} label="Dropoff Location" />
                )}
                onChange={(event, value: any) => setDropOffLocation(value)}
                value={dropOffLocation}
              />
            </Box>
            <Box py="5px">
              <TextField
                label="Dropoff Note"
                fullWidth
                value={dropOffNote}
                onChange={(e) => setDropOffNote(e.target.value)}
              />
            </Box>
          </DialogContentText>

          <DropzoneArea filesLimit={10} onChange={handleSelectFile} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Back
          </Button>
          <Button onClick={handleAccept} color="primary" autoFocus>
            Confirm Finish Delivery
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EndDeliveryDialog;
